import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import Subprocessors from '../components/slides/subprocessors/Subprocessors';

const Page = () => {
  return (
    <Layout>
      <SEO
        title="Subprocessors | Deadline Funnel"
        description="Subprocessors"
      />
      <Subprocessors />
    </Layout>
  )
};

export default Page;